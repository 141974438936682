@function theme-spacer($key: 0) {
    @return map-get($spacers, $key);
}

@mixin inintial_size($size, $font-size: 1rem) {
    width: $size !important;
    height: $size !important;

    &:before {
        width: $size !important;
        height: $size !important;
        line-height: $size !important;
        font-size: $font-size;
    }
}



.overflow-hidden {}

.overflow-auto {
    overflow: auto !important;
}

html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #f7f8fa url(../images/caret.svg) no-repeat right .75rem center;
    background-size: 11px;
}

.border-dashed-top {
    border-top: 1px dashed rgb(173, 204, 227) !important;
}

hr {
    border-top: 1px solid #eceff1 !important;
}


.border-dashed {
    border: 1px dashed rgb(173, 204, 227) !important;
}

.border {
    border: 1px solid rgb(173, 204, 227) !important;
}


.pointer {
    cursor: pointer !important;
}



$bagdeConfig: 0,
5,
10,
15,
20;

@each $tuple in $bagdeConfig {
    .top-#{($tuple)} {
        position: absolute !important;
        top: $tuple !important;
    }
}


$bagdeConfig: 0,
5,
10,
15,
20;

@each $tuple in $bagdeConfig {
    .bottom-#{($tuple)} {
        position: absolute !important;
        bottom: $tuple !important;
    }
}

$bagdeConfig: 0,
5,
10,
15,
20;

@each $tuple in $bagdeConfig {
    .left-#{($tuple)} {
        position: absolute !important;
        left: $tuple !important;
    }
}

$bagdeConfig: 0,
5,
10,
15,
20;

@each $tuple in $bagdeConfig {
    .right-#{($tuple)} {
        position: absolute !important;
        right: $tuple !important;
    }
}


.dropdown-menu {
    border: 1px solid #adcce3;
}

.text-primary {
    color: #005eff !important;

    &:hover {
        color: #005eff !important;
    }
}

a.text-primary:focus,
a.text-primary:hover {
    color: #005eff !important;
}

.rounded {
    border-radius: 5px !important;
}



.timeline-block {
    .timeline-item {
        border-left: 1px solid #dee2e4;
        position: relative;
        padding-left: 30px;
        padding-top: 3px;
        padding-bottom: 35px;
        margin-left: 10px;

        &:last-child {
            border-left-color: transparent !important;
        }

        p {
            // letter-spacing: 0.5px;
            font-weight: 400 !important;
            margin-bottom: 1px !important;

            &.lead {
                font-weight: 600 !important;
                font-size: 1rem;
            }
        }
    }
}


/*--------------
    Beam
---------------*/
.beam {
    width: 28px;
    height: 28px;
    display: block;
    position: absolute;
    left: -15px;
    top: 0;
    background-color: #d4eeee;
    border-radius: 50%;
    border: 2px solid #fff;

    &::after {
        content: "";
        width: 11px;
        height: 11px;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #7cccca;
        border-radius: 50%;
    }
}

$beam-variations: (sucess: #7cccca, failed: #FF7C7C);

@each $label,
$color in $beam-variations {
    .beam-#{$label} {
        background-color: lighten($color, 15%);

        &::after {
            background-color: $color;
        }
    }
}



/*--------------
    Person: Name with Image or Initials
---------------*/
.person .person-image,
[data-initials]::before,
.with-flag::before {
    content: attr(data-initials);
    display: inline-block;
    font-size: 0.9375rem;
    width: 43px;
    height: 43px;
    line-height: 43px;
    text-align: center;
    background: #4fc3f7;
    vertical-align: middle;
    color: white;
}

.person .person-image,
[data-initials]::before {
    margin-right: 1em;
    border-radius: 50%;
}

.person {
    img {
        display: none;
    }

    &.has-image {
        position: relative;
        display: inline-block;

        .person-image {
            position: relative;
            overflow: hidden;

            img {
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%);
                width: 100%;
                height: auto;
            }
        }
    }
}

// Circle with Image or Initials
[data-initials]::before {
    .has-image & {
        display: none;
    }
}

// Size Variations
.person-image.person-image-md {
    @include inintial_size(64px, 1.5rem);
}

.person-image.person-image-xmd {
    @include inintial_size(96px, 1.625rem);
}

.person-image.person-image-lg {
    @include inintial_size(128px, 1.875rem);
}

.person-image.person-image-xlg {
    @include inintial_size(137px, 1.875rem);
}

.person-image.person-image-xxlg {
    @include inintial_size(256px, 1.875rem);
}


//newsletter

.newsletter {
    overflow: hidden;
    height: 40px;
    // width: 400px;
    margin: 0 auto;
    position: relative;

    input {
        width: 100%;
        border: 1px solid #005eff;
        border-radius: 5px;
        height: 40px;
        padding: 10px 111px 10px 15px;
        // text-transform: uppercase;
        outline: none;
        transition: border .2s ease;

        &:focus {
            border: 1px solid #005eff;

            +button {
                background-color: #005eff;
            }
        }
    }

    button {
        position: absolute;
        z-index: 999;
        right: 2px;
        top: 2px;
        height: 36px;
        border: none;
        background-color: #005eff;
        transition: background-color .3s ease, width .3s ease;
        color: white;
        padding: 0 7px;
        margin: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        // text-transform: uppercase;
        width: auto;
        text-align: center;
        cursor: pointer;
        transform: translateZ(0);

        &:hover {
            width: 140px;
        }

        @media (max-width:576px) {
            // text-align: left;
            // direction: rtl;
        }
    }
}