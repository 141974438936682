.table {

    td,
    th {
        border-bottom: 1px dashed rgb(173, 204, 227) ;
        padding: 1.5rem .75rem;
    }

    thead{
        th{
            border-bottom: 1px dashed rgb(173, 204, 227) ;
        }
    }

    tbody {
        tr:hover {
            background-color: rgba(242, 242, 251, 0.49019607843137253);
        }
    }

    &.table-no-hover {
        tbody {
            tr:hover {
                    background-color: transparent;
            }
        }
    }

    &.nohover {
        tbody tr:hover {
            background-color: transparent;
        }
    }


}



.table.transaction-table,
.table.transaction-total-table {
    tr:first-child {

        td,
        th {
            @include media-breakpoint-up(lg) {
                padding-top: 0;
            }

           
        }
    }

    .td-amount {
        width: 150px;
        text-align: right;
    }
}

.table.transaction-total-table {
    margin-bottom: 0;

    tr {

        th,
        td {
            padding-bottom: 0px;
            padding: 0.5rem .75rem;
            border-bottom: none !important;
            font-weight: normal;
            font-size: 13px;
        }
    }

    &.table-no-hover {
        tbody {
            tr:hover {
                    background-color: transparent;
            }
        }
    }
}