.form-control {
    border: 1px solid #cdd3d8;
    background-color: #F7F7FD;
    border-radius: 2px;

    &::placeholder {
        color: #D2D2D7;
    }

    &:focus {
        border-color: #ADCCE3;
        box-shadow: none;
        z-index: 1 !important;
    }
}



.form-group {


    &.custom {
        position: relative;

        label {
            position: absolute;
            top: -13px;
            font-size: 17px;
            left: 21px;
            background: white;
            padding: 0 12px;
        }

        input,
        select,
        textarea {
            background: white;
            height: 65px;
            border-radius: 5px;
            padding-left: 32px;

            &:focus {
                border-color: #226be8;
            }
        }

        select {
            background: white url(../images/caret.svg) no-repeat right 1.75rem center;
            background-size: 11px;
        }

        textarea {
            height: 80px;
        }

        span {
            position: absolute;
            top: 24px;
            right: 18px;
            font-size: 12px;
            color: #005eff;
            cursor: pointer;
        }



    }
}

input.tel_num.form-control {
    display: inline;
    max-width: 60px;
    text-align: center;
    margin: 2.5px;
    height: 60px;
    padding: 0rem .2rem 0 !important;
}

input:-internal-autofill-selected {
    background-color: white !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: white !important;
    -webkit-box-shadow: 0 0 0 40px white inset !important;

}