#navbarAsideContent {
  padding-top: $navbar-fixed-top-padding;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: $sidebar-width; // box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: $navbar-fixed-top-padding;
  // transition: transform 0.3s, width 0.3s;
  transition: all .5s ease;
  overflow: hidden;
  transform: translateX(calc(-100% - 20px));
  background: #f2f2fb;
  border-right: 1px solid $navbar-main-outline-border-color;



  &.overview {
    width: $sidebar-overview-width;




    h4 {
      transition: left .3s;
      position: relative;
      left: 50px;
      white-space: nowrap;
    }

    .nav-item.active {
      background: unset;


      // .nav-link:visited{

      //   &+.progress {
      //     display: none;
  
      //     // &.100 {
      //     //   display: block;
      //     // }
      //   }
      // }
    }

    .progress {
      transition: left .3s;
      position: relative;
      left: 50px;
      white-space: nowrap;
      margin-top: 11px;
      height: 0.5rem;
      border-radius: 50px !important;
    }
  }

  @include media-breakpoint-up(lg) {
    transform: translateX(0);
  }

  .navbar {
    padding: 0;
  }

  .nav-item {
    position: relative;
    margin-bottom: 10px;

    &.active {
      background: #EAEFF4;
    }

    .nav-item {
      margin-bottom: 0;

      &:before {
        content: "";
        position: absolute;
        left: 27px;
        top: 50%;
        transform: translateY(-50%);
        height: 95%;
        width: 4px;
        border-left: 1px dashed #d1e4ee;
        opacity: 1;
        transition: all .3s;
      }

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        width: 8px;
        left: 36px;
        top: 55%;
        border-top: 1px solid #d1e4ee;
      }
    }



    &.active {


      .nav-link {
        p {
          color: #005CFC;
        }

        svg,
        svg .a {
          fill: #005CFC;
        }

        svg path {
          stroke: #005CFC;
        }

        svg circle {
          stroke: #005CFC;
        }

        svg line {
          stroke: #005CFC;
        }

        svg polyline {
          stroke: #005CFC;
        }

        svg polygon {
          stroke: #005CFC;
        }

      }
    }

    a.active {
      border-left: 4px solid theme-color("primary");
      color: theme-color("primary");

      p {
        color: #005CFC;
      }

      svg,
      svg .a {
        fill: #005CFC;
      }

      svg path {
        stroke: #005CFC;
      }

      svg line {
        stroke: #005CFC;
      }

      svg polyline {
        stroke: #005CFC;
      }

      svg polygon {
        stroke: #005CFC;
      }

    }

    .dropdown-menu {
      position: relative;
      box-shadow: none;

      @include media-breakpoint-up(lg) {
        top: 0;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: #fff;
        opacity: 0;
        transition: all 0.3s;
      }

      .dropdown-item {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 50px;
      }
    }
  }

  .nav-link {
    // height: 36px;
    transition: all 0.3s;
    position: relative;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    color: #4A546D;
    width: 260px;
    display: flex;
    align-items: center;
    padding: 17px 0;

    &+.progress {

      &.100 {
        display: none;
      }
    }



    // &:visited {
    //   color: rgb(233, 15, 15);

    //   &+.progress {
    //     display: none;

    //     &.100 {
    //       display: block;
    //     }
    //   }

    // }


    .arrow {
      display: inline-block;
      padding: 1.5px 5px;
      color: $body-color;
    }

    &[data-toggle].collapsed:before,
    &[data-toggle].collapsed:after,
    &[data-toggle]:not(.collapsed):after,
    &[data-toggle]:not(.collapsed):before {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      left: 114px;
      border: 4px solid transparent;
      z-index: 2;
    }

    &[data-toggle="collapse"].collapsed:before,
    &[data-toggle]:not(.collapsed):before {
      top: 50%;
      margin-top: 2px;
      transform: translateY(-50%);
      border-top-color: #7e8fa4;
    }

    &[data-toggle="collapse"].collapsed:after,
    &[data-toggle]:not(.collapsed):after {
      top: 50%;
      margin-top: 0;
      transform: translateY(-50%);
      border-top-color: #fff;
    }

    p {
      width: 100%;
      transition: left 0.3s;
      position: absolute;
      left: 50px;
      top: 17px;
      white-space: nowrap;
    }

    svg rect,
    svg circle,
    svg .a,
    svg polygon,
    svg line,
    svg polyline,
    svg path {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      text-decoration: none; // background-color: $navbar-light-active-color;

      p {
        color: #005CFC;
      }

      svg,
      svg .a {
        fill: #005CFC;
      }

      svg path {
        stroke: #005CFC;
      }

      svg circle {
        stroke: #005CFC;
      }

      svg line {
        stroke: #005CFC;
      }

      svg polyline {
        stroke: #005CFC;
      }

      svg rect {
        stroke: #005CFC;
      }

      svg polygon {
        stroke: #005CFC;
      }
    }
  }

  .nav-link-title {
    margin: 0;
    font-size: 0.9rem;
    line-height: 16px;
    position: relative;

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  .nav-link-icon {
    width: 16px;
    height: 16px;
    margin-left: 20px;
    font-size: 13px;
    line-height: 13px;
    color: $text-color;
    display: block;

    svg {
      width: 100%;
      height: auto;

      &,
      .a {
        fill: $text-color;
      }
    }
  }

  &.sidebar--small {
    transform: translateX(0);
  }

  @include media-breakpoint-up(lg) {
    &.sidebar--small {
      width: $sidebar-width-collapsed;
      overflow: visible;

      .sidebar__content {
        width: $sidebar-width-collapsed;
        overflow: visible !important;
        transition: width 0.3s;
      }

      .nav-link {
        overflow: hidden;
        width: $sidebar-width-collapsed;
        background-color: transparent;

        p {
          position: absolute;
          left: 65px;
          width: 100%;
          transition: left 0.3s;
        }

        .dropdown-toggle {

          &::before,
          &::after {
            display: none;
          }
        }

        &:hover {
          width: 260px;
          background-color: theme-color("primary");
          color: #fff;

          svg,
          svg .a {
            fill: #fff;
          }

          svg path {
            stroke: #fff;
          }


          svg line {
            stroke: #fff;
          }

          svg polyline {
            stroke: #fff;
          }

          svg rect {
            stroke: #fff;
          }

          svg polygon {
            stroke: #fff;
          }

          p {
            left: 50px;
            color: #fff;
          }
        }
      }
    }
  }
}


.wrapper {

  padding-top: 69px;

  &.wrapper--full-width {
    .container-wrap {
      padding-left: 0;

      @include media-breakpoint-up(sm) {
        padding-left: $sidebar-width-collapsed;
      }
    }
  }

  &.wrapper--no-sidebar {
    .container-wrap {
      padding-left: 0;
    }
  }
}

.container-wrap {
  padding-left: 0;
  transition: padding-left 0.3s;

  >.container {
    padding: $grid-gutter-width / 2;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $sidebar-width;
    // padding-top: $navbar-fixed-top-padding;
    // padding-top: 69px;

    // min-height: 95vh;

    >.container {
      padding: $grid-gutter-width;
    }

    &.overview {
      padding-left: $sidebar-overview-width;
    }

  }
}