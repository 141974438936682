// Container
$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1350px);

// Sidebar
$sidebar-width: 260px;
$sidebar-overview-width: 360px;
$sidebar-width-collapsed: 55px;
$breadcrumb-divider: quote(">");

$body-color: #364052;
$body-bg: #F7F8FB;

$theme-colors: (primary: #01c7b1,
  deep-primary: #009f9f,
  faded-gold: #FEE79B,
  faded-silver: #D5DCDF,
  faded-bronze: #E8BD9C);

$slate-gray-light: #D3E4EF;
$slate-gray: #7E8FA4;
$badge-bg-blue: #00BAEF;

$colors: (slate-gray : $slate-gray,
  slate-gray-light: $slate-gray-light,
  faded-gold: #FEE79B,
  faded-silver: #D5DCDF,
  faded-bronze: #E8BD9C);

// Main Navbar
$navbar-fixed-top-padding: 54px;
$navbar-main-outline-border-color: #ADCCE3;
$navbar-main-color: $body-color;
$navbar-main-active-color: #F7F8FB;
$navbar-main-toggler-border-color: $slate-gray-light;
$navbar-toggler-size: 1rem;
$navbar-link-padding-x: 1.25rem;


$text-color: #fff;
$text-muted: $slate-gray;