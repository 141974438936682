.card {
    border: none;

    .card-header {
        border: none;
        padding: 3.75rem 3.25rem;
        background-color: unset;

        .status-item {
            display: flex;
            align-items: center;


            h5 {
                line-height: 1.5;
                font-size: 32px;
                font-weight: 700;
                margin: 0;


                @media (max-width:576px) {

                    font-size: 18px;

                }



                small {
                    font-size: 19px;

                    @media (max-width:576px) {

                        font-size: 14px;

                    }
                }
            }

            h5:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                right: 10%;
                top: 0;
                background-color: #d3e4ef;
            }

            &:last-child {
                h5::after {
                    display: none;
                }
            }

            @media (max-width:992px) {
                &:nth-child(2n+2) h5::after {
                    display: none;
                }

            }
        }
    }

    .card-footer {
        padding: .75rem 1.25rem;
        background-color: rgba(173, 204, 227, 0.1);
        border-top: 1px solid rgb(173, 204, 227);
    }



}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(173, 204, 227, 0.1);
    border-top: 1px solid rgb(173, 204, 227);
}




.summary-services {


    .card {


        @media (min-width:1200px) {

            height: 360px;

        }

        @media (max-width:1200px) {

            height: 399px;

        }

        @media (max-width:768px) {

            height: unset;

        }

    }

}