.btn-primary {
    color: #fff;
    background-color: #45D9BE;
    border-color: #45D9BE;
    border-radius: 2px;
    font-size: 15px;
    padding: 7px 23px;
    box-shadow: rgba(23, 23, 23, 0.1) 0px 0.4rem 2rem 0px;

    &:hover {

        background-color: #3ec5ad;
        border-color: #3ec5ad;

    }

    &:focus {
        box-shadow: none;
    }

    &.lg {
        padding: 13px 43px;
        font-size: 21px;

        @media (max-width:576px) {
            padding: 7px 12px;
            font-size: 15px;
        }
    }
}


.btn-secondary {
    color: #fff;
    background-color: #005eff;
    border-color: #005eff;
    border-radius: 2px;
    font-size: 15px;
    padding: 7px 23px;
    box-shadow: rgba(23, 23, 23, 0.1) 0px 0.4rem 2rem 0px;

    &:hover {

        background-color: #154ba8;
        border-color: #154ba8;

    }

    &:focus {
        box-shadow: none;
    }

    &.lg {
        padding: 13px 43px;
        font-size: 21px;

        @media (max-width:576px) {
            padding: 7px 12px;
            font-size: 15px;
        }
    }
}


.mob {

    @media (max-width:576px) {
        width: 100%
    }

}


.btn-outline-secondary {
    color: #005eff;
    background-color: transparent;
    background-image: none;
    border-color: #005eff;
    box-shadow: rgba(23, 23, 23, 0.1) 0px 0.4rem 2rem 0px;

    &:hover {
        background-color: transparent;
        color: #005eff;
        border-color: #005eff;
    }
}


.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}


.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: none;
}