body {
    // color: #1e5085;
    -webkit-font-smoothing: antialiased;
    font-family: 'Inter';
    color: #010c21;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Inter' !important;
}

a {
    color: #072d4f;

    &:hover {
        color: #072d4f;
    }
}

h1 {
    @media (max-width: 768px) {
        font-size: 27px;
    }
}

.world-container {
    background: #005eff;
    // padding: 52px 0 90px;

    @media (max-width: 567px) {
        padding: 0;
    }

    .world-banner {
        height: 250px;
        background: #005eff;
        background-image: url(../images/map.png);
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;

        p {
            font-size: 22px;

            @media (max-width: 567px) {
                font-size: 19px;
            }
        }

        h1 {
            @media (max-width: 567px) {
                font-size: 30px;
            }
        }

        @media (max-width: 567px) {
            height: calc(100vh - 586px);
        }
    }
}

.contact-form {
    background: white;
    border: 1px solid #adcce3;
    text-align: center;
    padding: 100px 0 50px;
    position: relative;
    top: -148px;

    h3 {
        margin-bottom: 74px;

        @media (max-width: 567px) {
            margin-bottom: 50px;
        }
    }

    @media (max-width: 567px) {
        border: unset;
        top: unset;
        padding: 55px 0 50px;
    }
}

.vh {
    height: calc(100vh - 65px);
    background: white !important;
    overflow: auto;
}

.no-data-invoice {
    height: calc(100vh - 192px);
}

button:focus {
    outline: 1px dotted;
    outline: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #005eff;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid #005eff;
}

.nav-tabs .nav-link:focus {
    border-color: unset !important;
    border: none;
    outline: none;
    border-bottom: 2px solid #005eff;
}

.filters {
    a.btn {
        height: 100%;
        line-height: 2;
    }
}

@media (max-width: 768px) {
    .collapse.navbar-collapse {
        position: absolute;
        background: #ffffff;
        width: 100%;
        top: 71px;
        box-shadow: rgba(40, 73, 232, 0.22) 0px 1rem 1rem 0px;
    }

    .collapsing.navbar-collapse {
        position: absolute;
        background: #ffffff;
        width: 100%;
        top: 71px;
        box-shadow: rgba(40, 73, 232, 0.22) 0px 1rem 1rem 0px;
    }
}

.nav-tabs .nav-link:hover {
    border-color: transparent;
    color: #005eff;
}

.alert {
    position: absolute;
    width: 100%;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0;
    z-index: 99;
    left: 0;
    top: 70px;
    opacity: 0.9;
    text-align: center;
}

.bg-secondary {
    background-color: #005eff !important;
}

.modal-content {
    border-radius: 7px;
    overflow: hidden;
    border: none;
}

.input-group.date {
    input {
        padding-right: 36px;
    }

    .input-group-append {
        right: 0px;
        z-index: 1;
        top: 0px;
    }
}

.badge {
    width: 110px;

    &.badge-outline {
        background: transparent;
        font-weight: normal;
    }

    &.badge-success {
        color: #44d9be;
        border: 1px solid #44d9be;
    }

    &.badge-warning {
        color: #f7833b;
        border: 1px solid #f7833b;
    }

    &.badge-silver {
        color: #adcce3;
        border: 1px solid #adcce3;
    }
}

.bank-info {
    border: 1px dashed #adcce3;
    padding: 30px;
    margin-bottom: 24px;
    position: relative;

    .options {
        position: absolute;
        top: 13px;
        right: 27px;
    }
}

.sign-up {
    background: #fff;
    background-image: url(../images/2.png), url(../images/3.png);
    background-position: bottom, 91% -61px;
    background-size: 65rem, 10rem;
    background-repeat: no-repeat;
    color: #072d4f;
    height: calc(100vh - 0px);

    .wrapper {
        height: calc(100vh - 0px);
        overflow: auto;
    }

    @media (max-width: 576px) {
        height: unset;
        background-position: bottom, 91% -35px;
        background-size: 22rem, 5rem;

        .wrapper {
            padding-top: 0;
        }

        nav img {
            width: 115px;
        }
    }

    .breadcrumb {
        background-color: transparent;

        @media (max-width: 576px) {
            margin: 0;
            display: flex;
            justify-content: center;
        }
    }

    .form-group.custom {
        margin-bottom: 35px;
    }

    .breadcrumb-item {
        color: #97afc9;

        &.active {
            color: #072d4f;
        }
    }

    .breadcrumb-item+.breadcrumb-item:before {
        padding-right: 1.5rem;
        padding-left: 1.5rem;

        @media (max-width: 576px) {
            display: none;
        }
    }
}

footer {
    background: #005eff;
    // padding: 0px 0 94px 0;
    color: white;

    @media (max-width: 576px) {
        // padding: 0px 0 45px 0;
        font-size: 15px;

        svg {
            width: 131px;
        }
    }
}

.hero-section {
    padding: 112px 0;
    background: aliceblue;
    background-image: url(../images/spots.png);
    background-repeat: space;
    background-position: center;

    @media (max-width: 768px) {
        padding: 47px 0;
        background-size: cover;
    }
}

.svg-container {
    margin-bottom: -10px;
}

.login {
    // background: #030E38;

    .wrapper {
        height: calc(100vh) !important;
        padding: unset !important;

        // padding: 78px 151px;

        @media (max-width: 768px) {}

        @media (max-width: 576px) {
            padding: 78px 0px;
        }

        .form-side {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            background: #f7f7fd;

            .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 70%;
                background: white;
                padding: 103px 59px;
                border: 1px solid #adcce3;
                border-right: 0;
                height: 650px;
                position: relative;

                @media (max-width: 992px) {
                    border-right: 1px solid #adcce3;
                }

                @media (max-width: 576px) {
                    width: 95%;
                    padding: 72px 38px;
                    height: 546px;
                }

                .abstract {
                    position: absolute;
                    height: 90px;
                    top: -41px;
                    right: 80px;
                }
            }
        }

        .info-side {
            height: 100%;
            background: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;

            .inner {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 80%;
                background: #005eff;
                height: 650px;
                position: relative;

                img {
                    position: absolute;
                    top: 63px;
                    right: -35px;
                    width: 80px;
                }

                .carousel-indicators {
                    right: unset;
                }

                .carousel-item {
                    height: 500px;

                    .carousel-caption {
                        height: 100%;
                        text-align: left;
                        line-height: 2;
                        display: flex !important;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;

                        h2 {
                            line-height: 1.3;
                        }
                    }
                }
            }

            img {
                position: absolute;
                top: -63px;
                right: 205px;
                width: 150px;
            }
        }
    }
}

.started {
    background: white;

    @media (max-width: 576px) {
        .static-save {
            position: fixed;
            right: 0;
            left: 0;
            z-index: 1030;
            bottom: 0;
            background: #fff;
            padding: 15px 0;
            border-top: 1px solid #d3e4ef !important;
        }
    }

    .form-group {
        &.custom {
            position: relative;

            label {
                position: absolute;
                top: -13px;
                font-size: 17px;
                left: 21px;
                background: white;
                padding: 0 12px;
            }

            input,
            select,
            textarea {
                background: white;
                height: 65px;
                border-radius: 5px;
                padding-left: 32px;
            }

            select {
                background: white url(../images/caret.svg) no-repeat right 1.75rem center;
                background-size: 11px;
            }

            textarea {
                height: 80px;
            }
        }
    }

    .uploadDiv {
        border: 1px dashed #005eff;
        margin: 0 15px;
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;

        button {
            border: 1px dashed #005eff;
            color: #005eff;
            background: rgba(11, 69, 177, 0.08);
            font-size: 13px;
        }
    }

    .uploadlogo {
        border: 1px dashed #005eff;
        height: 150px;
        width: 150px;
        margin-bottom: 52px;
        margin-left: 15px;
        border-radius: 3px;
    }
}

.home {
    background: #f2f2fb;

    //#f2f2fb

    .table-div {
        height: calc(100vh - 527px);

        &.no-data {
            height: calc(100vh - 415px);
        }
    }

    .simple-invoice {
        display: flex;
        padding: 25px;
        justify-content: space-between;
        border-radius: 5px;
        align-items: center;
        border: 1px dashed #adcce3;

        i {
            opacity: 0;
        }

        &:focus {
            i {
                opacity: 1;
            }
        }
    }
}

.invoice {
    background: white;

    @media (max-width: 576px) {
        .static-save {
            position: fixed;
            right: 0;
            left: 0;
            z-index: 1030;
            bottom: 0;
            background: #fff;
            padding: 15px 0;
            border-top: 1px solid #d3e4ef !important;
        }
    }

    .form-group {
        &.custom {
            position: relative;

            label {
                position: absolute;
                top: -13px;
                font-size: 17px;
                left: 21px;
                background: white;
                padding: 0 12px;
            }

            input,
            select,
            textarea {
                background: white;
                height: 65px;
                border-radius: 5px;
                padding-left: 32px;
            }

            select {
                background: white url(../images/caret.svg) no-repeat right 1.75rem center;
                background-size: 11px;
            }

            textarea {
                height: 80px;
            }
        }
    }

    .uploadDiv {
        border: 1px dashed #005eff;
        margin: 0 15px;
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 5px;

        button {
            border: 1px dashed #005eff;
            color: #005eff;
            background: rgba(11, 69, 177, 0.08);
            font-size: 13px;
        }
    }

    .uploadlogo {
        border: 1px dashed #005eff;
        height: 150px;
        width: 150px;
        margin-bottom: 52px;
        margin-left: 15px;
        border-radius: 3px;
    }
}

.web {
    background: white;

    .chart {
        width: 100%;
        max-width: 427.5px;
        height: 416.49px;
        background: white;
        box-shadow: rgba(37, 48, 82, 0.18) 0px 0.4rem 2rem 0px;
        border-radius: 6px;
    }

    .web-title {
        position: relative;
        margin-bottom: 120px;
        margin-top: 77px;

        @media (max-width: 768px) {
            font-size: larger;
        }

        &::after {
            content: '';
            height: 3px;
            width: 73px;
            display: block;
            margin: 20px auto 0;
            background: #005eff;

            @media (max-width: 768px) {
                margin: 11px auto 0;
            }
        }
    }

    .box-section {
        background-image: url('../images/circle-bg.png');
        background-position: -70px;
        background-repeat: no-repeat;
        background-size: 199px;
    }

    .main-banner-text {
        &::after {
            content: '';
            height: 100%;
            position: absolute;
            width: 100%;
            left: -88px;
            z-index: -1;
            background-image: url('../images/shape.png');
            // background-position: -70px;
            // background-repeat: no-repeat;
            background-size: contain;
        }
    }

    .stats {
        margin-bottom: 130px;

        h2 {
            @media (max-width: 768px) {
                font-size: x-large;
            }
        }

        @media (max-width: 768px) {
            margin-bottom: 70px;
        }

        @media (max-width: 576px) {
            text-align: center;
        }

        img {
            width: 370px;

            @media (max-width: 768px) {
                width: 230px;
            }
        }
    }

    .appInv {
        max-width: 306.32px;
        width: 100%;
        height: 218.06px;
        background: #005eff;
        position: absolute;
        bottom: -71px;
        left: 282px;
        border-radius: 6px;

        @media (max-width: 1200px) {
            width: 427.5px;
            display: none !important;
            position: unset;
        }
    }
}

.statement {
    margin-bottom: 56px;

    @media (max-width: 576px) {
        margin-bottom: unset;
    }

    p {
        font-size: 19px;
        line-height: 1.7;

        @media (max-width: 786px) {
            font-size: 17px;
            line-height: 1.6;
        }
    }
}